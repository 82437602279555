@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
body,.app,.content,html,#root {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.app {
  display: flex;
  position: relative;
}
::-webkit-scrollbar {
  width: 10px;
}
/*track*/
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #8f8e8e;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.fc-theme-standard .fc-list-day-cushion {
  background : cornflowerblue
}